import React, { useState } from "react";
import styled from "styled-components";
import { CircleFlag } from "react-circle-flags";
import {
  chevronsButton, flexRowStartCenter,
  palette, textAlignCenter768,
} from "../../styles/styles";
import { ChevronLeft, ChevronRight } from "@styled-icons/fa-solid";
import {
  goPriorOrNext,
} from "../../utils/compsUtils";

const SuccessStories = (props) => {

  const { className } = props;
  const [currentStory, setCurrentStory] = useState(0);
  const stories = [
    {
      photo:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosSuccessStories%2FLiliana%20Iriarte%20-%20Foto.jpeg?alt=media&token=c2cade91-5e68-479b-802e-a45a1e4a9392",
      name: "Liliana Iriarte",
      post: "Senior Technical Writer",
      company: "Suggestic",
      comment:
        "La experiencia que tuve con LaPieza me encantó, fue transparente, sencilla y rápida. ¡Muy recomendable!",
      country: "bo",
    },
    {
      photo:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosSuccessStories%2FAdson%20Constanzi%20-%20Foto.jpeg?alt=media&token=73c989f0-5692-4f9b-95a9-5996be89d1da",
      name: "Adson Constanzi Filho",
      post: "Data Scientist",
      company: "Laboratoria",
      comment:
        "Mi career coach me ayudó desde el principio, preparándome y explicando cada etapa. Y además, ¡al final del proceso nos volvimos muy amigos!",
      country: "cl",
    },
  ];

  const changeStory = (action) => {
    goPriorOrNext(action, stories.length, currentStory, setCurrentStory);
  };

  return (
    <section className={className} id="successStories">
      <div className="left">
        <button
          className="iconButton"
          id="prior"
          type="button"
          onClick={() => changeStory("prior")}
        >
          <ChevronLeft className="icon" />
        </button>
        <div className="card">
          <div className="littleCard">
            <div className="photoWithImage">
              <img src={stories[currentStory].photo} alt="Talento colocado" />
              <CircleFlag className="flag" countryCode={stories[currentStory].country}/>
              {/* <p>
                {countryToFlag(
                  countries.find(
                    (c) => stories[currentStory].country === c.name
                  ).iso2
                )}
              </p> */}
             </div>

            <div className="lecturerInfo">
              <p className="lecturerName">{stories[currentStory].name}</p>
              <p className="lecturerPost">{`${stories[currentStory].post} - ${stories[currentStory].company}`}</p>
            </div>
          </div>
          <q>{stories[currentStory].comment}</q>
        </div>
        <button
          className="iconButton"
          type="button"
          onClick={() => changeStory("next")}
        >
          <ChevronRight className="icon" />
        </button>
      </div>
      <div className="right">
        <hr />
        <h2>Talento colocado</h2>
        <p>Nos sentimos muy orgullosos de todos nuestros pieceros.</p>
      </div>
    </section>
  );
};

export default styled(SuccessStories)`
  padding: 0 8%;
  display: grid;
  grid-template-columns: 55% 45%;
  column-gap: 5rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 4rem;
    padding: 0 4%;
  }
  .left {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    align-items: center;
    justify-items: center;
    .iconButton {
      ${chevronsButton};
    }
    .card {
      display: flex;
      flex-direction: column;
      align-items: start;
      grid-gap: 2rem;
      ${palette.boxShadow};
      box-shadow: ${palette.regBS};
      padding: 4rem;
      border-radius: 10px;
      .littleCard {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-gap: 2rem;
        @media (max-width: 768px){
          width: 100%;
          display: grid;
          grid-template-rows: min-content min-content;
          grid-template-columns: 1fr;
          grid-gap: 2rem;
          justify-items: center;
        }
        .photoWithImage {
          ${flexRowStartCenter};
          ${textAlignCenter768};
          img {
            border-radius: 50%;
            @media (max-width: 768px) {
              width: 12rem;
            }
          }
          .flag {
            width: 3rem;
            height: 3rem;
            align-self: flex-end;
            margin-left: -2.5rem;
          }
        }
        .lecturerInfo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .lecturerName {
            font-size: 2.6rem;
            ${textAlignCenter768};
          }
          .lecturerPost {
            color: ${palette.greenSuccesInfo};
            font-size: 1.5rem;
            ${textAlignCenter768};
          }
        }
      }
      p,
      q {
        font-size: 1.6rem;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    grid-gap: 2rem;
    hr {
      height: 0.5rem;
      width: 25%;
      margin: 0;
      background-color: ${palette.bgRed};
      border: none;
    }
    h2 {
      font-size: 3.5rem;
      color: ${palette.fontBlue};
      font-family: "SofiaProSemiBoldAz", sans-serif;
    }
    p {
      font-family: SofiaProRegularAz, sans-serif;
      font-size: 2.4rem;
      line-height: 3.7rem;
    }
    @media (max-width: 768px) {
      grid-row: 1 / 2;
      align-items: center;
      text-align: center;
    }
  }
`;
