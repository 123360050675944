import * as React from "react";
import styled from "styled-components";
import "../styles/globalStyles.scss";
import SuccessStories from "../comps/sections/SuccessStories";
import ServiceCharge from "../comps/sections/ServiceCharge";
import Lecturers from "../comps/sections/Lecturers";
import Cards from "../comps/sections/Cards";
import Partners from "../comps/sections/Partners";
import Header from "../comps/sections/Header";
import { palette } from "../styles/styles";
import Navbar from "../comps/Navbar";
import Footer from "../comps/Footer";
import first from "../images/sections/first.png";
import second from "../images/sections/chargeStain.png";
import circle from "../images/sections/circle.png";
import Faq from "../comps/sections/Faq";
import DrawerMenu from "../comps/DrawerMenu";
import Seo from "../comps/Seo";
import Profiles from "../comps/sections/Profiles";
import favicon from "../images/favicon.jpg";

const App = (props) => {
  const { className } = props;
  return (
    <>
      <Seo image={favicon} />
      <div className={className}>
        <Navbar />
        <DrawerMenu />
        <Header />
        <Partners />
        <Profiles />
        <Cards />
        <Lecturers />
        <ServiceCharge />
        <SuccessStories />
        <Faq />
        <Footer />
      </div>
    </>
  );
};
export default styled(App)`
  background-color: ${palette.bgWhite};
  max-width: 100%;
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 10rem;
  background-image: url(${first}), url(${first}), url(${circle}), url(${second});
  background-attachment: local;
  background-repeat: no-repeat;
  @media (min-width: 1440px) {
    background-position: 75rem -75rem, -80rem 170rem, -8rem 330rem,
    105rem 230rem;
    background-size: contain, 100rem, 20rem, 80rem;
  }
  @media (max-width: 1440px) {
    background-position: 75rem -75rem, -80rem 170rem, -8rem 330rem,
      105rem 230rem;
    background-size: contain, 100rem, 20rem, 80rem;
  }
  @media (max-width: 1024px) {
    background-position: 60rem -45rem, -80rem 180rem, -12rem 340rem,
      60rem 200rem;
    background-size: contain, 100rem, 28rem, contain;
  }
  @media (max-width: 768px) {
    background-position: 55rem -40rem, -80rem 222rem, -12rem 380rem, 50rem 250rem;
    background-size: contain, contain, 28rem, contain;
    grid-row-gap: 8rem;
  }
`;
