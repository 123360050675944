import React from "react";
import styled from "styled-components";
import { flexRowSeCt, palette } from "../../styles/styles";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Partners = (props) => {
  const { className } = props;

  const partners = [
    [
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FAirTM%20Logo.png?alt=media&token=d27c75c4-8387-413e-bac7-1b381ccb42a8",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FFEMSA%20Logo.png?alt=media&token=1d971dac-76fb-4cb0-b76c-83a66ca319a3",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FKikoya%20Logo.png?alt=media&token=6a9830a2-60d1-4e49-942b-6d65de0d8d0c",
    ],
    [
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FLogo%20Credijusto.png?alt=media&token=69ea8aad-c526-4426-b5fc-d714fd622bba",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FLogo%20Mercado%20Libre.png?alt=media&token=03e8905d-af45-404a-95cd-a1f361decf8d",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FLogo%20Oxxo.png?alt=media&token=00f16647-71f4-4715-a229-4353a94cd902",
    ],
  ];
  const partnersResp = [
    [
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FAirTM%20Logo.png?alt=media&token=d27c75c4-8387-413e-bac7-1b381ccb42a8",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FFEMSA%20Logo.png?alt=media&token=1d971dac-76fb-4cb0-b76c-83a66ca319a3",
    ],
    [
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FLogo%20Credijusto.png?alt=media&token=69ea8aad-c526-4426-b5fc-d714fd622bba",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FKikoya%20Logo.png?alt=media&token=6a9830a2-60d1-4e49-942b-6d65de0d8d0c",
    ],
    [
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FLogo%20Mercado%20Libre.png?alt=media&token=03e8905d-af45-404a-95cd-a1f361decf8d",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FLogo%20Oxxo.png?alt=media&token=00f16647-71f4-4715-a229-4353a94cd902",
    ],
  ];
  /* const partners = [
    [
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FAdereso%20Logo.jpeg?alt=media&token=cf0466d6-dc59-4e2e-aad3-90e5c0df6391",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FDoctoralia%20Logo.png?alt=media&token=17053f83-2dd2-4164-8933-dd314178c080",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FMINU%20Logo.png?alt=media&token=d87d3ecc-df5e-4260-9c72-4d81fd86d860",
    ],
    [
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FGraviti%20Logo.webp?alt=media&token=6bbcbda8-5a2f-4a99-a9a6-1269c7bf0ba4",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FHeru%20Logo.png?alt=media&token=a75c6e87-065b-4071-895d-2a124393c362",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FHigo%20Logo.png?alt=media&token=082d886c-bdc1-4051-a70a-e6270db85b16",
    ],
    [
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FID%20Finance%20Logo.png?alt=media&token=6bed0209-43e7-4068-b0a0-c1fcd3122754",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FInvolves%20Logo.png?alt=media&token=7bde3988-998b-4762-8645-7189a4c636c2",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FJu%CC%88sto%20Logo.svg?alt=media&token=31e104fd-9ff8-4c8b-b421-6f0979bbe06c",
    ],
    /*[
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FKikoya%20Logo.png?alt=media&token=6a9830a2-60d1-4e49-942b-6d65de0d8d0c",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FLocal%20Adventures%20Logo.png?alt=media&token=54e5a118-1d49-425a-b4f0-fc38cfa117c4",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FMorada%20Uno%20Logo.png?alt=media&token=df1f74b3-179f-4b40-a067-3e67a3d4dc71",
    ],
    [
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FMorada%20Uno%20Logo.png?alt=media&token=df1f74b3-179f-4b40-a067-3e67a3d4dc71",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FNimble%20Graviti%20Logo.png?alt=media&token=b6aeaf62-0b86-4d50-9cd2-a634687f12bb",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FNubity%20Logo.png?alt=media&token=bf9a44d1-d04d-4e79-b893-08de702ac6de",
      "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FLogosEmpresas%2FPayJoy%20Logo.png?alt=media&token=e479cc9b-68be-4ad2-92fe-2438ccb41c5a",
    ]
  ]; */

  return (
    <section className={className}>
      <h2>
        Capacitamos al talento para
        <br /> conectarlo con empresas increíbles
      </h2>
      <Carousel
        className="carousel"
        axis="horizontal"
        autoPlay
        renderThumbs={() => null}
        renderIndicator={() => null}
        renderArrowPrev={() => null}
        renderArrowNext={() => null}
        infiniteLoop
        interval={3000}
        showStatus={false}
      >
        {partners.map((pa) => (
          <div className={"slide"}>
            {pa.map((p) => (
              <img src={p} />
            ))}
          </div>
        ))}
      </Carousel>
      {/*  <Carousel
        className="carouselResp"
        axis="horizontal"
        autoPlay
        renderThumbs={() => null}
        renderIndicator={() => null}
        renderArrowPrev={() => null}
        renderArrowNext={() => null}
        infiniteLoop
        interval={3000}
        showStatus={false}
      >
        {partnersResp.map((pa) => (
          <div className={"slide"}>
            {pa.map((p) => (
              <img src={p} />
            ))}
          </div>
        ))}
      </Carousel> */ }
    </section>
  );
};

export default styled(Partners)`
  padding: 0 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  @media (max-width: 768px) {
    padding: 0 4%;
  }
  .carousel {
    @media (max-width: 768px) {
      display: none;
    }
  }
  .slide {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    img {
      width: 20% !important;
    }
  }
  h2 {
    font-size: 3.5rem;
    font-family: "SofiaProSemiBoldAz", sans-serif;
    color: ${palette.fontBlue};
    text-align: center;
  }
  p {
    font-size: 2.8rem;
    text-align: center;
  }
`;
