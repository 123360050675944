import React, { useState } from "react";
import styled from "styled-components";
import cardImg from "../../images/sections/cardImg.png";
import {palette, applyButton, chevrons, chevronsButton} from "../../styles/styles";
import { Link } from "gatsby";
import { ChevronLeft, ChevronRight } from "@styled-icons/fa-solid";
import { goPriorOrNext } from "../../utils/compsUtils";
import DetailsCard from "../DetailsCard";

const Cards = (props) => {
  const { className } = props;
  const cards = [
    {
      name: "Capacitaciones grupales con expertos (en vivo)",
      desc: "Tendrás acceso a 10 horas de contenido en vivo con expertos en los temas más actualizados y necesarios para conseguir trabajo en el mundo real. Algunos de los temas que verás son:",
      bullets: [
        "LinkedIn",
        "CV / Hoja de vida",
        "Marca personal",
        "Entrevistas laborales",
      ],
      index: 0,
    },
    {
      name: "Sesiones individuales 1 a 1",
      desc:
        "Tendrás un career coach que te ayudará a desarrollar tu perfil profesional y te acompañará durante todo el programa a través de sesiones personalizadas quincenales.\n" +
        "\n" +
        "Adicionalmente, contarás con 5 horas on demand en las que podrás pedir asesoría para algún tema en específico como:",
      bullets: [
        "Revisión de CV",
        "Asesoría en búsqueda de empleo",
        "Simulación de entrevista (español o inglés)",
      ],
      index: 1,
    },
    {
      name: "Paquete de heramientas\n digitales incluido",
      desc: "Contarás con acceso y capacitación a distintas herramientas digitales para ayudarte en tu búsqueda laboral.",
      bullets: [
        "Growth hacking",
        "Software de seguimiento de postulaciones",
        "Entre otras",
      ],
      index: 2,
    },
  ];
  const [currentCard, setCurrentCard] = useState(0);

  const changeCard = (action) => {
    goPriorOrNext(action, cards.length, currentCard, setCurrentCard);
  };

  return (
    <section className={className}>
      <h2 id="content">¿Qué incluye el Bootcamp?</h2>
      <div className="cards">
        {cards.map((c) => (
          <DetailsCard infoCard={c} />
        ))}
      </div>
      <div className="mobileCards">
        <button
          className="iconButton"
          id="prior"
          type="button"
          onClick={() => changeCard("prior")}
        >
          <ChevronLeft className="icon" />
        </button>
        <DetailsCard infoCard={cards[currentCard]} />
        <button
          className="iconButton"
          type="button"
          onClick={() => changeCard("next")}
        >
          <ChevronRight className="icon" />
        </button>  </div>
    </section>
  );
};

export default styled(Cards)`
  padding: 0 8%;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 4rem;
  @media (max-width: 768px) {
    padding: 0 4%;
  }
  h2 {
    font-family: "SofiaProSemiBoldAz", sans-serif;
    text-align: center;
    font-size: 3.5rem;
    color: ${palette.fontBlue};
  }
  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    @media (max-width: 768px) {
      display: none;
    }
  }
    .mobileCards {
      display: grid;
      grid-template-columns: 10% 80% 10%;
      align-items: center;
      justify-items: center;
      padding: 0 2rem;
      .iconButton {
       ${chevronsButton};
      }
      @media (min-width: 769px) {
        display: none;
      }
    }
  }
`;
