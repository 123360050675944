import React from "react";
import styled from "styled-components";
import premier from "../../images/sections/premier.png";
import { palette, applyButton } from "../../styles/styles";

const Header = (props) => {
  const { className } = props;

  return (
    <section className={className}>
      <div className="info">
        <h1>
          Bienvenido a
          <br /> <strong>Bootcamp LaPieza</strong>
        </h1>
        <p>
          Nuestro programa está diseñado para prepararte y acompañarte
          hasta que encuentres empleo. Lo mejor de todo es que solo pagas
          cuando consigues empleo.
        </p>
        <a
          href="https://form.typeform.com/to/QhcRLoIg"
          target="_blank"
          rel="noopener no referrer"
        >
          Postúlate ahora
        </a>
      </div>
      <div className="image">
        <figure>
          <img alt="header" src={premier} />
        </figure>
      </div>
    </section>
  );
};

export default styled(Header)`
  padding: 0 8%;
  display: grid;
  grid-template-columns: 55% 45%;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 4%;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-evenly;
    grid-gap: 4rem;
    @media (max-width: 768px) {
      align-items: center;
      text-align: center;
    }
    h1 {
      font-family: SofiaProSemiBoldAz, sans-serif;
      font-size: 5rem;
      color: ${palette.fontBlue};
      strong {
        color: ${palette.fontRed};
      }
    }
    p {
      font-family: SofiaProRegularAz, sans-serif;
      font-size: 2.4rem;
      width: 85%;
    }
    a {
      font-size: 2.9rem;
      ${applyButton}
    }
  }
  .image {
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
