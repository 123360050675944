import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ChevronDown } from "@styled-icons/fa-solid/ChevronDown";
import { chevrons, palette } from "../../styles/styles";

const Faq = (props) => {
  const { className } = props;
  const [currentSpreadQuestion, setCurrentSpreadQuestion] = useState(0);

  const faqs = [
    {
      q: "¿Qué es un Bootcamp?",
      a: "Es un programa de “alto impacto” que tiene como finalidad el que los participantes desarrollen habilidades prácticas en un tiempo muy corto, esto a través de clases intensivas y contenidos concentrados.",
      i: 1,
    },
    {
      q: "¿Qué duración tiene Bootcamp?",
      a: "Nuestro Bootcamp tiene una duración de 3 meses y medio, en los cuales recibirás capacitación y acompañamiento constante.",
      i: 2,
    },
    {
      q: "¿Cuánto cuesta el Bootcamp?",
      a: "Nuestro servicio representa una inversión de un mes de sueldo, el cual podrás pagar a 3 meses sin intereses a partir de tu primera quincena, una vez que hayas conseguido empleo.",
      i: 3,
    },
    {
      q: "¿Qué pasa si no consigo empleo antes de los 3 meses?",
      a: "La mayoría de nuestros alumnos consiguen empleo, pero si tú no lo haces, entonces no pagarás absolutamente nada.",
      i: 4,
    },
  ];

  const toggleQuestion = (i) => {
    if (currentSpreadQuestion === i) {
      setCurrentSpreadQuestion(0);
    } else {
      setCurrentSpreadQuestion(i);
    }
  };

  const question = (q) => {
    return (
      <div className="item">
        <div className="question">
          <p>{q.q}</p>
          <ChevronDown className="icon" onClick={() => toggleQuestion(q.i)} />
        </div>
        <Collapsable isOpen={currentSpreadQuestion === q.i}>
          <p className="answer">{q.a}</p>
        </Collapsable>
        <hr />
      </div>
    );
  };
  return (
    <section className={className}>
      <h2>Preguntas frecuentes</h2>
      {faqs.map((q) => question(q))}
    </section>
  );
};

export default styled(Faq)`
  padding: 2% 8% 6% 8%;
  margin-bottom: -12rem;
  background-color: ${palette.bgFaq};
  display: grid;
  grid-template-rows: max-content;
  grid-gap: 2rem;
  @media (max-width: 768px) {
    padding: 2% 4% 6% 4%;
  }
  h2 {
    color: ${palette.fontRed};
    font-size: 3rem;
  }
  p {
    font-size: 2.4rem;
    color: ${palette.fontBlue};
    margin-bottom: 2rem;
    text-align: left;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    .question {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: max-content;
      // border-bottom: ${palette.borderBlue} solid 2px;
      .icon {
        width: 2rem;
        color: ${palette.fontRed};
        cursor: pointer;
      }
    }
    .collapse {
      overflow: hidden;
      transition: 0.5s max-height;
    }
    .collapse > div {
      overflow: auto;
    }
    .answer {
      font-size: 2.2rem;
      color: ${palette.fontAnswers};
      margin-bottom: 2rem;
      text-align: left;
    }
    hr {
      width: 100%;
      height: 0.2rem;
      border: none;
      border-radius: 4px;
      background-color: ${palette.fontBlue};
    }
  }
`;

const Collapsable = (props) => {
  const { children, isOpen } = props;
  const [childHeight, setChildHeight] = useState('0');
  const content = useRef(null);

  useEffect(() => {
    const childHeightRaw = content.current.clientHeight + 10;
    setChildHeight(`${childHeightRaw}px`);
  }, []);

  return (
    <div
      className="collapse"
      style={{
        maxHeight: isOpen ? childHeight : 0,
      }}
    >
      <div ref={content}>{children}</div>
    </div>
  );
};