import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Done } from "@styled-icons/material";
import { Link } from "gatsby";
import {applyButton, flexColSbCenter, flexColStSt, palette} from "../styles/styles";
import cardImg from "../images/sections/cardImg.png";

const DetailsCard = (props) => {
  const { className, infoCard, shortVersion } = props;
  return (
    <div className={className}>
      <img src={cardImg} alt="card" />
      <div className="infoAndLink">
        <h3>{infoCard.name}</h3>
      <div className="content">
        {shortVersion ? null : <p className="cardDesc">{infoCard.desc}</p>}
        <ul>
          {infoCard.bullets.map((b) => (
            <li>
              <Done className="icon" />
              {b}
            </li>
          ))}
        </ul>
      </div>
      {shortVersion ? null : (
        <Link className="link" to="/info" state={{ index: infoCard.index }}>
          Conoce más detalles
        </Link>
      )}
      </div>
    </div>
  );
};

export default styled(DetailsCard)`
  background-color: ${palette.whiteWhite};
  ${flexColStSt};
  box-shadow: ${palette.regBS};
  border-radius: 10px;
  .infoAndLink {
    ${flexColSbCenter};
    width: 100%;
    height: 100%;
    margin-top: 2rem;
    display: grid;
    grid-template-rows: 10rem auto auto;
    @media (min-width: 1150px){
      grid-template-rows: 8rem auto auto;
    }
  }
    h3 {
      font-size: 2.4rem;
      line-height: 25px;
      padding: 1rem 3rem;
      font-family: "SofiaProSemiBoldAz", sans-serif;
      color: ${palette.fontBlue};
      align-self: flex-start;
    }
    .content {
      padding: 1rem 3rem;
      line-height: 2.5rem;
      align-self: flex-start;
      p {
        font-size: 1.6rem;
        max-width: 100%;
        line-height: 25px;
        font-family: "SofiaProMediumAz", sans-serif;
      }

      ul {
        margin: 2rem 0;
        padding: 0;

        li {
          display: grid;
          grid-template-columns: 1fr 4fr;
          font-size: 1.6rem;
          color: #43474c;
          .icon {
            width: 4rem;
            margin-right: 2rem;
            color: #43474c;
          }
        }
      }
    }

    .link {
      ${applyButton};
      border-radius: 0 0 8px 8px;
      font-size: 2.2rem;
      width: 100%;
      text-decoration: none;
      text-align: center;
      align-self: flex-end;
  }
`;
