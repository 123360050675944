import React, { useState } from "react";
import styled from "styled-components";
import {chevrons, chevronsButton, flexRowStartCenter, palette, textAlignCenter768} from "../../styles/styles";
import { ChevronLeft, ChevronRight } from "@styled-icons/fa-solid";
import { goPriorOrNext } from "../../utils/compsUtils";
import insta from "../../images/insta.png";
import linke from "../../images/linke.png";

const Lecturers = (props) => {
  const { className } = props;
  const [currentLecturer, setCurrentLecturer] = useState(0);

  const lecturers = [
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FAzucena%20.jpeg?alt=media&token=740d2153-f413-492b-8a9a-9914595af694",
      name: "Azucena Moranchel Alvarado",
      post: "Social Media & Content Manager",
      quote:
        "Si tienes grandes ideas, usa grandes palabras para expresarlas.",
      descp:
        "",
      linkedin: "https://www.linkedin.com/in/azucena-moranchel/",
    },
    {
      photo:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FAlexa%20Villegas.jpeg?alt=media&token=cf9bd8c3-bee1-47fa-a171-e94036eb100b",
      name: "Alexa Villegas",
      post: "Headhunter Manager & Sales",
      quote:
        "Todo es posible con perseverancia, disciplina y amor por lo que haces",
      descp:
        "Lic. en Administración de Negocios Internacionales. Experiencia en Headhunting, management de equipos y ventas. Apasionada por encontrar el mejor talento y hacerlo funcionar!",
      insta: "https://www.instagram.com/a.villegass/",
      linkedin: "https://www.linkedin.com/in/alexa-villegas-236506199/",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FAna%20Rodriguez.jpeg?alt=media&token=192d9fcc-c2fb-4ea8-84dc-2fdd405ce9da",
      name: "Ana Karen Rodriguez Martínez",
      post: "Headhunter Coordinator",
      quote: "No te detengas, hasta que estes orgullos@",
      descp:
        "Lic. en Psicología con 4 añós de experiencia en RH, apasionada en ayudar a las personas a encontrar el trabjo de sus sueños.",
      insta: "",
      linkedin: "https://www.linkedin.com/in/anarodriguez1604/",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FAndrea%20Carmona%20Ramos.jpeg?alt=media&token=63342ad8-c8d0-40b9-a6a8-a1c6d2a337f1",
      name: "Andrea Carmona Ramos",
      post: "Headhunter",
      quote:
        "Dicen que hagas lo que amas pero yo lo cambiaría por amar lo que haces, la magia está en los detalles de tu día a día.",
      descp:
        "Administradora y Psicóloga Clínica, 3 años de experiencia en RH y 3 años en Psicoanálisis, me encanta juntar lo mejor de dos mundos y todo lo que tenga que ver con el desarrollo humano :D",
      insta: "https://www.instagram.com/andreacarmona_r",
      linkedin: "https://www.linkedin.com/in/andrea-carmona-ramos-4b9b16149/",
    },
    {
      photo:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FBrisa%20Chumba.jpeg?alt=media&token=63133c0c-88eb-4f57-bd2c-01037438b1b8",
      name: "Brisa Chumba",
      post: "Ninja Headhunter Intern",
      quote:
        "Sé paciente, a veces hay que pasar por lo peor para conseguir lo mejor.",
      descp: "",
      insta: "https://www.instagram.com/brichumba",
      linkedin: "https://www.linkedin.com/in/brisa-chumba/",
    },
    {
      photo:
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FCarolina%20Lo%CC%81pez%20Solis%20.jpeg?alt=media&token=b3ab4ddf-5e11-45fb-99d9-def2240fe9c8",
      name: "Cynthia Patricia Balderas Sánchez",
      post: "Headhunter Intern",
      quote: "",
      descp:
        "Licenciada en Piscología con diplomado en liderazgo en compromiso social.  Ha sido líder de diferentes programas que ayudan a potenciar las habilidades de jóvenes que buscan hacer un cambio positivo en el mundo. Su experiencia laboral se enfoca en reclutamiento de talento para diferentes industrias.",
      insta: "https://www.instagram.com/cynthiabss_",
      linkedin: "https://www.linkedin.com/in/cynthia-balderas-a57335141/",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FDania%20Bo%CC%81rquez%20Herna%CC%81ndez.jpeg?alt=media&token=6e1f0cf3-7964-461d-af99-7cc64ec6b01e",
      name: "Dania Bórquez Hernández",
      post: "Headhunter, Sales Representative & Customer Success specialist",
      quote: "Nada es imposible, respira, levantate, sonríe y sigue adelante. Si luchas por lo que quieres tarde o temprano llegará",
      descp: "Estudiante de la licenciatura de Negocis Internacionales (a 2 meses de ser licenciada). Experiencia en reclutamiento, ventas y customer success. Palabras o cosas que me definen: compromiso, pasión y amor por lo que hago, entrega total, empatía y trabajo en equipo.",
      insta: "https://www.instagram.com/dania.borquez",
      linkedin: "https://www.linkedin.com/in/daniaborquez-business/",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FErick%20Rami%CC%81rez.jpeg?alt=media&token=77f2c632-e786-4ba3-a7f9-76e3d0977001",
      name: "Erick Armando Ramírez Colunga",
      post: "Headhunter and Sales Representative ",
      quote: "Lo importante no es cuántas veces caes, sino cuántas veces que te levantas y continuas",
      descp: "3 años de experiencia en RH, Licenciado en Admón de Empresas, Maestro en Psicología Organizacional, experiencia como docente.",
      insta: "https://www.instagram.com/erick_rcolunga",
      linkedin: "www.linkedin.com/in/erickrcolunga",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FErika%20Campos.jpeg?alt=media&token=ba4e666c-0be0-4dc6-9fc6-aa776f910571",
      name: "Erika Campos",
      post: "Ninja HeadHunter ",
      quote: "Podran robarte las ideas, pero nunca el TALENTO!",
      descp: "Administradora de empresas con experiencia en RH, apasionada con la gestion de talento humano, programas de capacitación y desarrollo de competencias! Me encantan los perritos :)",
      insta: "https://www.instagram.com/camposerika697",
      linkedin: "https://www.linkedin.com/in/erika96/",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FGeraldina%20Untermann.jpeg?alt=media&token=7cdc8864-c998-473a-b8c7-e17b1a0a4bf6",
      name: "Geraldina Untermann",
      post: "Headhunter",
      quote: "Buscar trabajo es una aventura, solo debes lanzarte a ella. ¡Permítenos ser parte!",
      descp: "Licenciada y Profesora en Psicología. Experiencia en reclutamiento y como profesora adscripta en la Universidad Nacional de Córdoba. Me encanta aprender cosas nuevas, los desafíos.",
      insta: "https://www.instagram.com/geraldina_untermann",
      linkedin: "https://www.linkedin.com/in/geraldina-untermann-1a44131a1/",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FKarina%20Villamar%20Lau.jpeg?alt=media&token=ef071a75-68e6-4cce-a54e-2aee2124cbc1",
      name: "Karina Villamar Lau",
      post: "Partnerships Representative & Headhunter",
      quote: "Todos podemos hacer algo, aunque al principio parezca pequeño, para mejorar la vida de alguien más.",
      descp: "",
      insta: "https://www.instagram.com/karilau",
      linkedin: "https://www.linkedin.com/in/karina-a-villamar-lau-513673165/",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FMaria%20Daniela%20Bongianino.jpeg?alt=media&token=d50845a1-d22e-4e4b-aa47-147466276f24",
      name: "Maria Daniela Bongianino",
      post: "Headhunter Intern",
      quote: "El éxito es la suma de pequeños esfuerzos que se repiten cada día",
      descp: "Licenciada en Recursos Humanos. Orientación a la Responsabilidad Social Empresaria, y participación en proyecto de inclusión laboral para personas con discapacidad. Participación en procesos de selección de personal. Llevo trabajando 1 mes en LaPieza.io",
      insta: "https://www.instagram.com/daniela.bong",
      linkedin: "linkedin.com/in/maría-daniela-bongianino-50314b215",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FMario%20Romello%CC%81n.jpeg?alt=media&token=69a62c66-f8a1-425a-be09-4802dc3b3c9e",
      name: "Mario Romellón Ramírez",
      post: "Headhunter and Sales Development Representative",
      quote: "Toma todas las oportunidades que lleguen a tu vida que hagan sentido con tus valores personales",
      descp: "3 años de experiencia en liderazgo y 1 año de experiencia en reclutamiento, Proximo Licenciado en comunicación y Medios Digitales. Nómada Digital.",
      insta: "https://www.instagram.com/mario_romellon",
      linkedin: "https://www.linkedin.com/in/mario-romellon/",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FPol.jpg?alt=media&token=3821be56-8866-4040-bd80-2d974f05d270",
      name: "Pol Morral Dauvergne",
      post: "Co-founder & CEO",
      quote: "Esto es el inicio de algo grande",
      descp: "Licenciado en Negocios Internacionales por parte de Neoma Business School (Francia) y la UDLAP (México), Pol es mentor Endeavor; miembro del consejo de la French Tech México y co-fundador y CEO de LaPieza.io: una startup de reclutamiento que colabora hoy en día con más de 300 empresas en América Latina como FEMSA, Mercado Libre, AbInBev o Wizeline. Pol, de nacionalidades francesa y española, es especialista en startups; growth hacking y reclutamiento. A sus 26 años, Pol está liderando un equipo de +35 personas, con la ambición de generar un impacto global.",
      insta: "https://www.instagram.com/pol_morral",
      linkedin: "https://www.linkedin.com/in/pol-morral-dauvergne/",
    },
    {
      photo: "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/BootcampResources%2FPhotosLecturers%2FMelanie%20Jascovich.jpeg?alt=media&token=7afc529e-e465-4112-8446-c83b22a634c4",
      name: "Melanie Jascovich",
      post: "Ninja Headhunter",
      quote: "",
      descp: "Licenciada en Recursos Humanos, 4 años de experiencia en el area de HR realizando diversas tareas del departamento y reclutando a todo tipo de perfiles.",
      insta: "https://www.instagram.com/meljascovich",
      linkedin: "https://www.linkedin.com/in/melanie-aylen-jascovich-7557b2140/",
    },
  ];

  const changeLecturer = (action) => {
    goPriorOrNext(action, lecturers.length, currentLecturer, setCurrentLecturer);
  };

  return (
    <section className={className}>
      <div className="left">
        <button
          className="iconButton"
          id="prior"
          type="button"
          onClick={() => changeLecturer("prior")}
        >
          <ChevronLeft className="icon" />
        </button>
        <div className="card">
          <div className="littleCard">
            <img
              src={lecturers[currentLecturer].photo}
              className="photoLecturer"
              alt="lecturers"
            />
            <div className="lecturerInfo">
              <p className="lecturerName">{lecturers[currentLecturer].name}</p>
              <p className="lecturerPost">{lecturers[currentLecturer].post}</p>
              <div className="social">
                {lecturers[currentLecturer].insta ? (
                  <a
                    href={lecturers[currentLecturer].insta}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={insta} alt="instagram" />
                  </a>
                ) : null}
                <a
                  href={lecturers[currentLecturer].linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linke} alt="linkedin" />
                </a>
              </div>
            </div>
          </div>
          <q className="lecturerQuote">{lecturers[currentLecturer].quote}</q>
          <p className="lecturerDesc">{lecturers[currentLecturer].descp}</p>
        </div>
        <button
          className="iconButton"
          type="button"
          onClick={() => changeLecturer("next")}
        >
          <ChevronRight className="icon" />
        </button>
      </div>
      <div className="right">
        <hr />
        <h2>Career coaches y ponentes</h2>
        <p>
          Expertos en reclutamiento para startups y corporativos en USA, México
          y LATAM
        </p>
      </div>
    </section>
  );
};

export default styled(Lecturers)`
  padding: 0 8%;
  display: grid;
  grid-template-columns: 55% 45%;
  column-gap: 5rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 4rem;
    padding: 0 4%;
  }
  .left {
    display: grid;
    grid-template-columns: 10% 80% 10%;
    align-items: center;
    justify-items: center;
    .iconButton {
      ${chevronsButton};
    }
    .card {
      background-color: ${palette.whiteWhite};
      display: flex;
      flex-direction: column;
      align-items: start;
      grid-gap: 2rem;
      box-shadow: ${palette.regBS};
      padding: 4rem;
      border-radius: 10px;
      .littleCard {
        display: grid;
        grid-template-columns: 1fr 5fr;
        grid-gap: 2rem;
        align-items: center;
        @media (max-width: 768px){
          width: 100%;
          display: grid;
          grid-template-rows: min-content min-content;
          grid-template-columns: 1fr;
          grid-gap: 2rem;
          justify-items: center;
        }
        .photoLecturer {
          border-radius: 50%;
          @media (max-width: 768px) {
            width: 30%;
          }
        }
        .lecturerInfo {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .lecturerName {
            font-size: 2.6rem;
            ${textAlignCenter768};
          }
          .lecturerPost {
            font-size: 2rem;
            ${textAlignCenter768};
          }
          .social {
            ${flexRowStartCenter};
            grid-gap: 1rem;
            ${textAlignCenter768};
          }
        }
      }
      .lecturerQuote {
        font-size: 1.6rem;
        font-family: "SofiaProLightAz", sans-serif;
        font-style: italic;
      }
      .lecturerDesc {
        font-size: 1.6rem;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    grid-gap: 2rem;
    hr {
      height: 0.5rem;
      width: 25%;
      margin: 0;
      background-color: ${palette.bgRed};
      border: none;
    }
    h2 {
      font-size: 3.5rem;
      color: ${palette.fontBlue};
      font-family: "SofiaProSemiBoldAz", sans-serif;
    }
    p {
      font-family: SofiaProRegularAz, sans-serif;
      font-size: 2.4rem;
      line-height: 3.7rem;
    }
    @media (max-width: 768px) {
      grid-row: 1 / 2;
      align-items: center;
      text-align: center;
    }
  }
`;
