import React from "react";
import styled from "styled-components";
import { flexRowStartStart, palette } from "../../styles/styles";
import chargeImg from "../../images/sections/chargeImg.png";

const ServiceCharge = (props) => {
  const { className } = props;
  const steps = [
    {
      step: 1,
      title: "Aplica al programa",
      desc: "Llena el formulario de aplicación y se aceptado.",
    },
    {
      step: 2,
      title: "Conoce a tu Career Coach",
      desc: "Inicia tu seguimiento 1 a 1 con el Career Coach que te acompañará hasta que encuentres el empleo de tus sueños.",
    },
    {
      step: 3,
      title: "Asiste a las capacitaciones grupales",
      desc: "Desarrolla de la mano de nuestros expertos todas las habilidades que necesitas para ingresar a una empresa increíble.",
    },
    {
      step: 4,
      title: "¡Felicidades! Sabíamos que conseguirías empleo",
      desc: "Llegó el momento que tanto estabas esperando.",
    },
    {
      step: 5,
      title: "Invierte en todo lo que has aprendido",
      desc: "Inicia tu proceso de pago de un mes de sueldo a tres mensualidades (sin intereses).",
    },
    {
      step: 6,
      title: "¡Bienvenido a la familia de egresados!",
      desc: "Forma parte de la comunidad internacional de pieceros, participa en nuestras sesiones de networking, asiste a nuestro webinars especiales para ti y muchos beneficios más.",
    },
  ];

  return (
    <section className={className} id="serviceCharge">
      <div className="left">
        <hr />
        <h2>¿Cómo funciona?</h2>
        <h4 className="warrant">
          Si no consigues empleo en 3 meses, nuestro programa no tendrá ningún
          costo.
        </h4>
        {steps.map((s) => (
          <div className="step">
            <p className="number">{s.step}</p>
            <div className="info">
              <strong>{s.title}</strong>
              <p>{s.desc}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="right">
        <figure>
          <img src={chargeImg} alt="pricing" />
        </figure>
      </div>
    </section>
  );
};

export default styled(ServiceCharge)`
  padding: 0 8%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 5rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 4%;
  }
  .left {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    @media (max-width: 768px) {
      align-items: center;
    }
    hr {
      height: 0.5rem;
      width: 25%;
      margin: 0;
      background-color: ${palette.bgRed};
      border: none;
    }
    h2 {
      font-size: 3.5rem;
      color: ${palette.fontBlue};
    }
    .warrant {
      color: ${palette.fontRed};
      font-size: 2.4rem;
      margin-bottom: 2rem;
    }
    .step {
      display: grid;
      grid-template-columns: 8% 1fr;
      width: 95%;
      grid-gap: 4rem;
      .number {
        font-size: 7rem;
        color: ${palette.blueNumbers};
        align-self: start;
      }
      .info {
        strong {
          font-size: 2.6rem;
          color: ${palette.fontBlue};
          font-family: "SofiaProSemiBoldAz", sans-serif;
          margin-bottom: 1rem;
        }
        p {
          font-family: SofiaProRegularAz, sans-serif;
          font-size: 2.4rem;
          line-height: 3.3rem;
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    figure {
      @media (max-width: 1024px) {
        margin-top: -28rem;
      }
      img {
        @media (max-width: 1024px) {
          max-width: 80%;
        }
      }
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
