import React from "react";
import styled from "styled-components";
import de from "../../images/sections/profilesIcons/Data Engineer.png";
import ds from "../../images/sections/profilesIcons/Data Scientist.png";
import dsw from "../../images/sections/profilesIcons/Desarrollo Software & Web.png";
import fa from "../../images/sections/profilesIcons/Finance & Accounting .png";
import hr from "../../images/sections/profilesIcons/Human Resources.png";
import mk from "../../images/sections/profilesIcons/Marketing.png";
import pm from "../../images/sections/profilesIcons/Product Management.png";
import s from "../../images/sections/profilesIcons/Sales.png";
import ux from "../../images/sections/profilesIcons/UX_UI Designer.png";
import more from "../../images/sections/profilesIcons/Y más.png";
import {
  flexColCtCt,
  flexColEndCt,
  flexRowSbCt,
  flexRowStartCenter,
  palette,
  sectionLayout,
} from "../../styles/styles";

const Profiles = (props) => {
  const profiles1 = [
    { name: "Data Engineer", img: de },
    { name: "Data Scientist", img: ds },
    { name: "Desarrollo Software & Web", img: dsw },
    { name: "Finance & Accounting", img: fa },
    { name: "Human Resources", img: hr },
  ];
  const profiles2 = [
    { name: "Marketing", img: mk },
    { name: "Product Management", img: pm },
    { name: "Sales", img: s },
    { name: "UX / UI Designer", img: ux },
    { name: "Y más", img: more },
  ];

  const { className } = props;

  return (
    <section className={className}>
      <h2>
        Perfiles con los que tenemos
        <br /> experiencia
      </h2>
      <div className="profiles">
        <div className="firstRow">
          {profiles1.map((p) => (
            <div className="profileItem">
              <img className="icon" src={p.img} alt={p.name} />
              <strong>{p.name}</strong>
            </div>
          ))}
        </div>
        <div className="secondRow">
          {profiles2.map((p) => (
            <div className="profileItem">
              <img className="icon" src={p.img} alt={p.name} />
              <strong>{p.name}</strong>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default styled(Profiles)`
  ${sectionLayout};
  padding: 2% 8%;
  background-color: ${palette.bgProfiles};
  ${flexColCtCt};
  @media (max-width: 768px) {
    padding: 3% 4%;
  }
  h2 {
    font-size: 3.6rem;
    font-family: "SofiaProSemiBoldAz", sans-serif;
    text-align: center;
    color: ${palette.whiteWhite};
    margin-bottom: 4rem;
  }
  .profiles {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-row-gap: 4rem;
    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
    @media (max-width: 425px) {
      grid-template-columns: repeat(2, auto);
      grid-template-rows: auto;
    }
    .firstRow,
    .secondRow {
      ${flexRowSbCt};
      @media (max-width: 768px) {
        ${flexColCtCt};
        grid-gap: 2rem;
      }
    }
    .profileItem {
      ${flexColEndCt};
      font-family: "SofiaProSemiBoldAz", sans-serif;
      color: ${palette.fontBlue};
      font-size: 2.2rem;
      grid-gap: 2rem;
      .icon {
        width: 8rem;
      }
    }
  }
`;
